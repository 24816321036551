<template>
  <el-main class="rh-form">
    <form-hcactivitecreate :routes="hcmRoutes" />
  </el-main>
</template>
<script>
import FormHcactivitecreate from "@/components/rh/formulaires/FormHcactivitecreate";

export default {
  components: { "form-hcactivitecreate": FormHcactivitecreate },
  computed: {
    hcmRoutes() {
      return {
        toedit: {
          name: "hcactiviteedit",
          params: {
            idhcm: this.$store.state.hcm.hcactivitemedecinLastCreatedId,
          },
        },
        tolist: { name: "hcactivites" },
      };
    },
  },
};
</script>
