<template>
  <div>
    <h1>Ajouter une activité hors-clinique</h1>
    <form-hcactivite :ismeddetail="ismeddetail" :isedit="false" ref="formHcm">
    </form-hcactivite>
    <el-divider></el-divider>
    <div style="margin-top: 8px">
      <el-button type="primary" :disabled="isSaving" @click="onSubmit"
        >Enregistrer</el-button
      >
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </div>
</template>
<script>
import FormHcactivite from "@/components/rh/ui/form/FormHcactivite";
import { trimStringInObj } from "@/fonctions";

export default {
  components: { "form-hcactivite": FormHcactivite },

  data() {
    return {
      isSaving: false,
    };
  },
  props: {
    ismeddetail: Boolean,
    routes: Object,
  },
  created() {
    // récupère l'id du médecin si dans détail médecin
    const idMed = this.$route.params.idmed;
    this.$store.commit("hcm/resetHCactivitemedecin", idMed);
  },
  computed: {
    hcactiviteCreateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.hcm.hcactivitemedecinCreateStatus;
    },
  },
  methods: {
    onSubmit() {
      this.$refs.formHcm.$refs.formHcactivite.validate((valid) => {
        if (valid) {
          let hcmdata = this.$store.state.hcm.hcactivitemedecin;

          // enlever les espaces avant et après dans les string
          trimStringInObj(hcmdata);

          // vérifie si l'activité hc ne chevauche pas une même activité crée pour ce médecin
          const hcmInBD = this.$store.getters["hcm/HcactiviteInBD"];
          const doublon =
            hcmInBD.filter(
              (m) =>
                m[1] == hcmdata.id_med &&
                m[2] == hcmdata.id_hcactivite &&
                m[3] < hcmdata.fin_date &&
                m[4] > hcmdata.debut_date
            ).length > 0;
          if (doublon) {
            this.$alert(
              "Cette activité HC chevauche une activité identique déjà existante pour ce médecin",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur lance la création du nouvel enregistrement
            this.$store.dispatch("hcm/createHcactivitemedecin", hcmdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      // retourne à la liste des formations continues
      this.$router.push(this.routes.tolist);
    },
  },
  watch: {
    hcactiviteCreateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.hcactiviteCreateStatus == 2) {
        // message succès
        this.$message({
          message: "L'activité a été ajouté-e à la base de donnée",
          type: "success",
        });

        // va au formulaire d'édition du contrat
        this.$router.push(this.routes.toedit);
      } else if (this.hcactiviteCreateStatus == 3) {
        this.isSaving = false;
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      } else if (this.hcactiviteCreateStatus == 1) {
        this.isSaving = true;
      } else if (this.hcactiviteCreateStatus == 0) {
        this.isSaving = false;
      }
    },
  },
};
</script>
